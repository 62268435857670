<template>
  <the-error/>
  <div id="safe-area-top-scroll-fix"/>
  <the-header v-if="!isLoading && isHeaderVisible"/>
  <the-notices/>

  <router-view v-slot="{ Component }" name="singleComponent">
    <transition name="route" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <router-view v-slot="{ Component }" name="fullWidthTop">
    <transition name="route" mode="out-in">
      <component :is="Component" />
    </transition>
  </router-view>

  <div v-if="!isLoading" class="content-page" :class="contentPageClass">
    <div class="container">
      <router-view v-slot="{ Component }">
          <transition name="route" mode="out-in">
              <component :is="Component"/>
          </transition>
      </router-view>
    </div>
  </div>
  <transition name="zoom" mode="out-in">
      <the-splash v-if="isLoading" :finish-animation="finishAnimation"/>
  </transition>

  <the-promt v-if="goToPage"
             :ok-func="continueGoToPage"
             :cancel-func="cancelGoToPage"
             header-class="border-0 theme-color pb-2"
             body-class="p-0 border-0"
             footer-class="justify-content-center border-0 pt-0"
             :close="cancelGoToPage"
  >
    <template #header><h5 class="text-center w-100">You did not submit your changes! <br>Proceed without submitting?</h5></template>
    <template #body><h5 class="text-center w-100"></h5></template>
    <template #okText>Confirm</template>
    <template #cancelText>Cancel</template>
  </the-promt>
  <the-promt v-if="shouldExit"
             :ok-func="exitApp"
             :cancel-func="cancelExitApp"
             header-class="border-0 theme-color pb-2"
             body-class="p-0 border-0"
             footer-class="justify-content-center border-0 pt-0"
             :close="cancelExitApp"
  >
    <template #header><h5 class="text-center w-100">Close DualDialogue?</h5></template>
    <template #okText>Exit</template>
    <template #cancelText>Cancel</template>
  </the-promt>


  <photo-post v-if="isPhotoPostVisible" />
</template>

<script>
    import "@/assets/styles/app.scss";
    import {defineAsyncComponent} from "vue";
    import {ROUTE_NAME_TAG, ROUTE_NAME_LEADERS_MAP, ROUTE_NAME_LANDING} from "@/const/routeNames";
    import TheHeader from "./components/layout/TheHeader";
    import TheSplash from "./components/layout/TheSplash";
    import TheNotices from "./components/layout/notices/TheNotices";
    import TheError from "./components/layout/TheError";
    import ThePromt from "@/components/modals/ThePromt";
    import {App} from "@capacitor/app";
    import { StatusBar, Style } from '@capacitor/status-bar';
    import { Capacitor } from '@capacitor/core';
    import Color from '@/const/colors'
    import pullToRefresh from "@/hooks/pullToRefresh";
    import {getCookie, setCookie} from "@/functions/cookie";

    export default {
      name: 'DualDialogue',
      mixins: [pullToRefresh],
      components: {
        PhotoPost: defineAsyncComponent(() =>
          import('@/components/photos/PhotoPost')
        ),
        ThePromt,
        TheError,
        TheHeader,
        TheSplash,
        TheNotices,
      },
      created() {
        this.setTheme();
        this.changeIcon();
        this.changePhoneIcon();
        this.setDarkMode();
        this.connectWs();
        this.fetchConfig();
        this.fetchCurrentUser();
        this.setLandingModalVisibility();
        window.addEventListener("beforeunload", this.checkGlobalEditMode)
      },
      watch: {
         $route (to, from){
           const to_uid = to.params.slug || 'home';
           const from_uid = from.params.slug || 'home';
           if (to_uid !== from_uid || !this.$store.state.tags.currentTag) {
             this.fetchCurrentTag()
           }
         },
        '$route.hash' (to){
          if (/#autoteamup/.test(to.hash)) {
            const uid = to.hash.split('_')[1]
            window.location.hash = ''
            if (!uid) return

            this.autoTeamupRequestFromHash(uid)
          }
        },
        '$store.state.config.theme'(currVal, oldVal) {
          if (currVal && currVal !== oldVal) {
            this.setTheme();
            this.changeIcon()
            this.changePhoneIcon()
          }
        },
        '$store.state.config.dark_mode'(currVal, oldVal) {
          if (currVal && currVal !== oldVal) this.setDarkMode();
        },
        '$store.state.auth.isLoading'(currVal) {
          if (!currVal && this.animationFinished && !this.$store.getters['tags/isTagLoading']) this.isLoading = false;
        },
        '$store.state.tags.isLoading'(currVal) {
          if (!currVal && this.animationFinished && !this.$store.getters['auth/isUserLoading']) this.isLoading = false;
        },
        animationFinished(val) {
           if (val && !this.$store.getters['tags/isTagLoading'] && !this.$store.getters['auth/isUserLoading']) {
             this.isLoading = false;
           }
        },
        '$store.state.auth.currentUser.notices_count'() {
          this.updateBrowserTitle()
        },
        '$store.state.tags.currentTag.uid'() {
          this.logTag()
          this.updateBrowserTitle()
        },
        '$route.query.access_token'(accessToken) {
          if (accessToken) {
            this.socialAuthWithToken(accessToken)
          }
        },
        isLoading(val) {
           if (!val && !this.$store.state.auth.isLoggedIn){
             localStorage.removeItem('landingModalHidden')
           }
           if (!val &&
             !localStorage.getItem('landingModalHidden') &&
             this.$route.name === ROUTE_NAME_TAG
           ) {
              setTimeout(() => {
              this.$router.push({name: ROUTE_NAME_LANDING, params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
              }, 1000)
           }
          if (!val) {
            this.checkAutoTeamup()
          }
        },
      },
      data(){
        return {
          isLoading: true,
          animationFinished: false,
        }
      },
      computed: {
        currentTagUid() {
          return this.$store.getters['tags/currentTagUid']
        },
        globalEditMode() {
          return this.$store.getters['editMode']
        },
        goToPage() {
          return this.$store.getters['goToPage']
        },
        shouldExit() {
          return this.$store.getters['shouldExit']
        },
        isPhotoPostVisible() {
          return this.$store.getters['photoPost/isVisible']
        },
        contentPageClass() {
          return this.$route.meta.blackBg ? 'bg-black' : ''
        },
        isHeaderVisible() {
          return this.$route.name !== ROUTE_NAME_LEADERS_MAP
        },
      },
      methods: {
        async fetchCurrentUser() {
          try {
            this.$store.dispatch('auth/autoLogin');
          } catch (error) {
            this.setFetchTimeout('fetchCurrentUser');
            this.$store.dispatch('setError', error.message || "App:fetchCurrentUser: failed!");
          }
        },
        async fetchConfig() {
          try {
            this.$store.dispatch('auth/config');
          } catch (error) {
            this.setFetchTimeout('fetchConfig');
            this.$store.dispatch('setError', error.message || "App:fetchConfig: failed!");
          }
        },
        async fetchCurrentTag() {
          try {
            this.$store.dispatch('tags/setCurrentTag', {slug: this.$route.params.slug || 'home'});
          } catch (error) {
            this.setFetchTimeout('fetchCurrentTag');
            this.$store.dispatch('setError', error.message || "App:fetchCurrentTag: failed!");
          }
        },
        setLandingModalVisibility() {

        },
        setTheme() {
          //TODO ALEX REBUILD WITH StatusBar.setBackgroundColor
          const theme = this.$store.state.config.theme
          const platform = Capacitor.getPlatform()
          if (Capacitor.isNativePlatform() ) {
            if (platform === 'ios') {
              if (theme === 'night') {
                StatusBar.setStyle({ style: Style.Dark });
              } else {
                StatusBar.setStyle({ style: Style.Light });
              }
            } else if (platform === 'android') {
              StatusBar.setBackgroundColor({color: Color[theme]})
            }
          }
          document.querySelector('html').dataset.theme = theme;
        },
        setDarkMode() {
          document.querySelector('body').classList.add('mode-loading')
          if (this.$store.state.config.dark_mode === 'on') {
            document.querySelector('body').classList.add('dark')
          } else {
            document.querySelector('body').classList.remove('dark')
          }
          document.querySelector('html').dataset.dark_mode = this.$store.state.config.dark_mode;
          window.setTimeout(()=> {
            document.querySelector('body').classList.remove('mode-loading')
          }, 300)
        },
        setFetchTimeout(funcName ) {
          setTimeout(()=> {
            this[funcName]()
          }, 10000)
        },
        connectWs() {
          this.$store.dispatch('connectWs')
        },
        logTag() {
          if (!this.$store.state.tags.currentTag) {
            return console.log('NO UID')
          }
          const usrStr = this.$store.state.tags.currentTag.isUser ? `(user uid: ${this.$store.state.tags.currentTag.user.uid})` : ''
          console.log(`%c${this.$store.state.tags.currentTag.uid} ${usrStr}: ${this.$store.state.tags.currentTag.display_name}`, 'background: #222; color: #bada55')
        },
        updateBrowserTitle() {
          if (!this.$store.state.tags.currentTag?.type) {
            return
          }

          const isLoggedIn = this.$store.state.auth.isLoggedIn
          const noticeCount = this.$store.state.auth.currentUser?.notices_count
          const currentTagType = this.$store.state.tags.currentTag.type
          const currentTagDisplayName = this.$store.state.tags.currentTag.display_name

          let title = isLoggedIn && noticeCount > 0 ? `(${noticeCount}) ` : ''
          title += currentTagType === 'home' ? 'DualDialogue' : currentTagDisplayName

          document.getElementsByTagName('title')[0].textContent = title
        },
        changeIcon() {
          const oldLink = document.querySelector('link[type="image/x-icon"]')
          if ( oldLink ) {
            document.head.removeChild(oldLink)
          }
          const link = document.createElement('link')
          link.rel = 'shortcut icon'
          link.type = "image/x-icon"
          link.href = this.imgPath + `/${this.$store.state.config.theme}.ico`
          document.head.appendChild(link)
        },
        changePhoneIcon() {
          const oldMeta = document.querySelector('meta[name="apple-mobile-web-app-title"]')
          if ( oldMeta ) {
            document.head.removeChild(oldMeta)
          }
          const oldLink = document.querySelector('meta[name="apple-mobile-web-app-title"]')
          if ( oldLink ) {
            document.head.removeChild(oldLink)
          }
          ['60', '76', '120', '152', '180'].map((size) => {
            const link = document.createElement('link')
            link.rel = 'apple-touch-icon'
            link.sizes = `${size}x${size}`
            link.href = `/public/${this.$store.state.config.theme}_apple-touch-icon-${size}x${size}.png?time=${Date.now()}`
            document.head.appendChild(link)
          })

          const newMeta = document.createElement('meta')
          newMeta.name = 'apple-mobile-web-app-title'
          newMeta.content = 'DualDialogue'
          document.head.appendChild(newMeta)
        },
        continueGoToPage() {
          this.$store.commit('setEditMode', null)
          this.$router.push(this.goToPage)
          this.$store.commit('setGoToPage', null)
        },
        cancelGoToPage() {
          this.$store.commit('setGoToPage', null)
        },
        exitApp() {
          App.exitApp()
        },
        cancelExitApp() {
          this.$store.commit('setShouldExit', null)
        },
        checkGlobalEditMode(e) {
          if (this.globalEditMode) {
            e.returnValue = "Your did not save your edit - proceed without saving?"
            return e
          }
        },
        async socialAuthWithToken(accessToken) {
          await this.$store.dispatch('auth/socialAuth', {accessToken})
        },
        finishAnimation() {
          this.animationFinished = true
        },
        checkAutoTeamup() {
          if (/#autoteamup/.test(this.$route.hash)) {
            const uid = this.$route.hash.split('_')[1]
            window.location.hash = ''
            if (!uid) return

            this.autoTeamupRequestFromHash(uid)
          }
        },
        async autoTeamupRequestFromHash(uid) {
          if (this.$store.state.auth.isLoggedIn) {
            await this.$store.dispatch('teammates/teamUpRequest', {uid: Number(uid)})
          } else {
            let new_cookie = uid
            const old_cookie = getCookie('auto_teamup_uids')
            if (old_cookie) {
              new_cookie = `${old_cookie},${new_cookie}`
            }
            setCookie('auto_teamup_uids', new_cookie, 1)
            await this.$router.push({name: 'sign-in', params: {slug: this.$store.getters['tags/currentTagSlugNoHome']}})
          }
        },
      },
      beforeUnmount() {
        this.$store.dispatch('disconnectWs')
        window.removeEventListener("beforeunload", this.checkGlobalEditMode)
      },
    }
</script>
