<template>
  <div v-if="error" class="text-inverse theme-bg fs-110 p-2" id="error">{{error}}</div>
</template>

<script>
export default {

  data() {
    return {
      timeoutId: null,
      timeoutMS: 10000
    }
  },
  watch: {
    error(newVal) {
      if (newVal) {
        this.showError()
      }
    }
  },
  computed: {
    error() {
      return this.$store.getters['error']
    }
  },
  methods: {
    showError() {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(this.hideError, this.timeoutMS)
    },
    hideError() {
      clearTimeout(this.timeoutId);
      this.$store.dispatch('setError', null);
    }
  },
}
</script>
